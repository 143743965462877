import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "@/router/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style/style.css";

Vue.use(ElementUI);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

// ... 其他导入 ...

// 在创建 Vue 实例之前添加这些日志
console.log('Window object:', window);
console.log('myExternalConfig:', window.myExternalConfig);

if (window.myExternalConfig) {
  console.log('External config found:', window.myExternalConfig);
  Vue.prototype.$externalConfig = window.myExternalConfig;
} else {
  console.warn('External config not found. Using default values.');
  Vue.prototype.$externalConfig = {
    USER_NAME: 'Default User'
    // 其他默认配置...
  };
}

new Vue({
  router,
  render: (h) => h(App),
  beforeCreate() {
    console.log('Vue instance $externalConfig:', this.$externalConfig);
  }
}).$mount("#app");